<template>
  <main class="about">
    <LandingHeader />
    <div class="meet">
      <h1 class="meet__title">{{ $t("landing.about.title") }}</h1>
      <p class="meet__text">
        {{ $t("landing.about.subtitle") }}
      </p>
      <img alt="" class="meet__bg-logo-left" src="@/assets/landing/bg-logo-white.png" />
      <img alt="" class="meet__bg-logo-right" src="@/assets/landing/bg-logo-white.png" />
    </div>

    <div class="team">
      <h3 class="team__title">{{ $t("landing.about.executiveTeam") }}</h3>
      <div class="team__grid">
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/Jorge_Perez_Torres.jpg" />
          <p class="team__grid__item__title">Jorge Perez Torres</p>
          <p class="team__grid__item__qualification">MD, MS, MPH, DrPH*</p>
          <p class="team__grid__item__role">Co-founder & Chief Executive Officer</p>
        </div>
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/Albert_Gonzales.jpg" />
          <p class="team__grid__item__title">Albert Gonzales</p>
          <p class="team__grid__item__qualification">MD</p>
          <p class="team__grid__item__role">Co-founder & Chief Medical Officer</p>
        </div>
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/solad.webp" />
          <p class="team__grid__item__title">Yauheni Solad</p>
          <p class="team__grid__item__qualification">MD, MHS, MBA*</p>
          <p class="team__grid__item__role">Senior Technology Advisor</p>
        </div>
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/Jennifer_Kasmin.jpg" />
          <p class="team__grid__item__title">Jennifer Kasmin</p>
          <p class="team__grid__item__qualification">BSN, RN</p>
          <p class="team__grid__item__role">Chief Compliance Officer</p>
        </div>
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/Lauren_Tharp.jpg" />
          <p class="team__grid__item__title">Lauren Tharp</p>
          <p class="team__grid__item__qualification">MD</p>
          <p class="team__grid__item__role">Chief Marketing Officer</p>
        </div>
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/konstantin.jpg" />
          <p class="team__grid__item__title">Konstantin Bouratchek</p>
          <p class="team__grid__item__qualification">MA</p>
          <p class="team__grid__item__role">Director of Business Development</p>
        </div>
      </div>
      <img alt="" class="team__splat-right" src="@/assets/landing/splat.png" />
      <img alt="" class="team__splat-left" src="@/assets/landing/splat.png" />
    </div>

    <div class="council">
      <h3 class="council__title">{{ $t("landing.about.advisory") }}</h3>
      <div class="council__grid">
        <div class="team__grid__item">
          <img alt="" class="team__grid__item__img" src="@/assets/landing/Alejandro_Ramirez.jpg" />
          <p class="team__grid__item__title">Alejandro Ramirez</p>
          <p class="team__grid__item__qualification">MD</p>
          <p class="team__grid__item__role">Primary Health</p>
        </div>
        <div class="team__grid__item">
          <!-- <img src="@/assets/landing/solad.png" alt="" class="team__grid__item__img" /> -->
          <div class="team__grid__item__img"></div>
          <p class="team__grid__item__title">Michael S. Sinha</p>
          <p class="team__grid__item__qualification">MD, JD, MPH</p>
          <p class="team__grid__item__role">Healthcare law</p>
        </div>
        <div class="team__grid__item">
          <!-- <img src="@/assets/landing/solad.png" alt="" class="team__grid__item__img" /> -->
          <div class="team__grid__item__img"></div>
          <p class="team__grid__item__title">Troy Naquin</p>
          <p class="team__grid__item__qualification">SMSgt, BS</p>
          <p class="team__grid__item__role">Community planning</p>
        </div>
      </div>
    </div>

    <LandingFooter />
  </main>
</template>

<script>
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";

export default {
  name: "About",
  components: {
    LandingFooter,
    LandingHeader,
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
  },
};
</script>

<style lang="scss" scoped>
.about {
  .meet {
    padding: 100px 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 100px 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 66px;
      line-height: 76px;
      margin-bottom: 16px;
      text-align: center;
      z-index: 5;

      @media (max-width: 768px) {
        font-size: 44px;
      }
    }

    &__text {
      line-height: 32px;
      font-size: 18px;
      text-align: center;
      max-width: 560px;
      width: 100%;
      z-index: 5;

      @media (max-width: 768px) {
        line-height: 28px;
      }
    }

    &__bg-logo-left {
      position: absolute;
      left: 10%;
      top: 70px;

      @media (max-width: 768px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__bg-logo-right {
      position: absolute;
      right: 10%;
      top: 70px;
      width: 145px;
      height: 145px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .team {
    padding: 110px 40px;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 50px 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 80px;
      z-index: 5;
      text-align: center;
      position: relative;

      @media (max-width: 768px) {
        text-align: center;
        font-size: 24px;
        margin-bottom: 40px;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 80px;
      column-gap: 30px;
      position: relative;
      z-index: 5;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 50px;
      }

      &__item {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          align-items: center;
        }

        &__img {
          width: 300px;
          height: 300px;
          border-radius: 50%;
          margin-bottom: 40px;
          background-color: $primaryblack2;

          @media (max-width: 768px) {
            width: 160px;
            height: 160px;
            margin-bottom: 20px;
          }
        }

        &__title {
          font-weight: bold;
          font-size: 24px;

          @media (max-width: 768px) {
            font-size: 18px;
            margin-bottom: 0;
          }
        }

        &__qualification {
          font-size: 18px;
          font-weight: bold;
          color: $primary;

          @media (max-width: 768px) {
            font-size: 16px;
            margin-bottom: 0;
          }
        }

        &__role {
          font-size: 24px;
          max-width: 320px;
          width: 100%;

          @media (max-width: 768px) {
            font-size: 18px;
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }

    &__splat-left {
      position: absolute;
      left: -180px;
      bottom: 230px;
      transform: rotate(70deg);

      @media (max-width: 768px) {
        width: 250px;
      }
    }

    &__splat-right {
      position: absolute;
      top: 60px;
      right: -60px;

      @media (max-width: 768px) {
        top: 130px;
        width: 200px;
      }
    }
  }

  .council {
    padding: 120px 40px;
    background-color: var(--graybg);

    @media (max-width: 768px) {
      padding: 50px 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 80px;
      text-align: center;

      @media (max-width: 1024px) {
        text-align: center;
      }
      @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 40px;
      column-gap: 40px;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &__item {
        display: flex;
        align-items: center;

        @media (max-width: 1024px) {
          min-width: 420px;
        }
        @media (max-width: 480px) {
          min-width: 320px;
        }

        &__img {
          width: 160px;
          height: 160px;
          border-radius: 50%;
          margin-right: 30px;

          @media (max-width: 768px) {
            width: 100px;
            height: 100px;
          }
        }

        &__text {
          &__name {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 0;

            @media (max-width: 768px) {
              font-size: 18px;
            }
          }

          &__qualification {
            font-size: 18px;
            color: $primary;

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
